import { inputStylingProps } from "@/components/shared/inputs/utils"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { InputField, InputProps } from "rtu-components"
import { Icon } from "../../Icons"
import Typography from "../Typography"

type Props = Omit<InputProps, "name" | "error"> & { name?: string; error?: string }

export const Password = (props: Props) => {
  const { t } = useTranslation(["auth", "validations", "common"])
  const [hidden, setHidden] = useState(true)

  return (
    <InputField
      renderLabel={() => <Typography type="caption">{t("auth:password.label")}</Typography>}
      name="password"
      {...inputStylingProps}
      placeholder={t("auth:password.placeholder")}
      type={hidden ? "password" : "text"}
      error={props.error}
      validation={{
        rules: {
          required: { message: t("validations:required"), value: true },
          minLength: {
            message: t("validations:password.length"),
            value: 8,
          },
        },
      }}
      renderRight={() => (
        <Icon
          name={hidden ? "EyeOff" : "Eye"}
          onClick={() => setHidden((prev) => !prev)}
          className="mr-4 cursor-pointer text-primary-300"
        />
      )}
      {...props}
    />
  )
}
